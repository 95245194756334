import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const NotFoundPage = () => (
  <>
    <Layout title="404: Not Found | The Cao Farm" headerTitle="NOT FOUND">
      <p>You just hit a route that doesn&#39;t exist... sadness.</p>
      <p>
        You should go <Link to="/">home</Link>.
      </p>
    </Layout>
  </>
);

export default NotFoundPage;
